import { captureException } from '@sentry/react';
import { toast } from 'react-toastify';

export const useError = () => {
  const create = (error: string, axios: any) => {
    captureException(axios);

    toast.error(error);

    if (
      axios.response &&
      axios.response.data &&
      axios.response.data.description &&
      Array.isArray(axios.response.data.additional) &&
      axios.response.data.additional.length > 0
    ) {
      toast.warning(
        axios.response.data.description + ': ' + axios.response.data.additional.join(', '),
      );
    }
  };

  return create;
};
