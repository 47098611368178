import cn from 'classnames';

import { IconProps } from './Icon.props';

export const Icon = ({ icon, color, className, type = 'solid', ...props }: IconProps) => {
  const faType = {
    solid: 'fas',
    regular: 'far',
    light: 'fal',
    brands: 'fab',
  }[type];

  return <i style={{ color }} className={cn(`fa-${icon}`, faType, className)} {...props} />;
};
