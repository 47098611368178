import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { ProductTable } from '@Components/tables';
import { ProductStatus } from '@Services/Product';

export const ProductMaoPage = () => {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={false} title={t('PRODUCT.MAO_PAGE.TITLE')}>
      <Card title={t('PRODUCT.PLURAL')}>
        <ProductTable status={ProductStatus.CREATED} />
      </Card>
    </Page>
  );
};
