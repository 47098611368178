import * as Yup from 'yup';

import { MAIL, PASSWORD, PHONE } from '@socialbrothers/constants';

import i18n from '@Root/setupLocales';

export const yupSetup = () => {
  Yup.setLocale({
    mixed: {
      required: i18n.t('VALIDATION.YUP.MIXED.REQUIRED'),
      notType: i18n.t('VALIDATION.YUP.MIXED.NOT_TYPE'),
    },
    string: {
      email: i18n.t('VALIDATION.YUP.STRING.INVALID_EMAIL'),
    },
    array: {
      min: i18n.t('VALIDATION.YUP.ARRAY.MIN'),
      max: i18n.t('VALIDATION.YUP.ARRAY.MAX'),
    },
  });
};

export const YupValidateRegex = (name: string, regex: RegExp) => {
  return Yup.string().test(name, '', function (value: any) {
    const { path, createError } = this;

    if (!value) {
      return true;
    }

    return (
      (value && value.match(regex)) ||
      createError({ path, message: i18n.t(`VALIDATION.YUP.STRING.INVALID_${name.toUpperCase()}`) })
    );
  });
};

Yup.addMethod(Yup.string, 'password', (ref: string) => {
  // Check if password is equal to the reference
  if (ref) {
    return Yup.string().test('password', '', function (value: any) {
      const { path, createError } = this;

      return (
        this.parent[ref] === value ||
        createError({ path, message: i18n.t('VALIDATION.YUP.STRING.MATCH_PASSWORD') })
      );
    });
  }

  // Check if password is in correct format
  return YupValidateRegex('password', PASSWORD);
});

Yup.addMethod(Yup.string, 'phone', () => {
  return YupValidateRegex('phone', PHONE);
});

Yup.addMethod(Yup.string, 'mail', () => {
  return YupValidateRegex('mail', MAIL);
});

Yup.addMethod(
  Yup.string,
  'async',
  (call: (field: string, value: string) => Promise<boolean>, oldValue?: string) => {
    return Yup.string().test('async', '', async function (value: any) {
      const { path, createError } = this;

      if (value === '' || value === oldValue) {
        return true;
      }

      const response = await call(path, value);

      return (
        response || createError({ path, message: i18n.t('VALIDATION.YUP.STRING.INVALID_ASYNC') })
      );
    });
  },
);

export default Yup;
