import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Form } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { Yup } from '@socialbrothers/utils';

import { useError } from '@Hooks/useError';
import { ProductService, ProductVioRequest } from '@Services/Product';

export const ProductVioCreateForm = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formRef = useRef<any>();
  const error = useError();

  const validationSchema = Yup.object({
    productStockLines: Yup.array(
      Yup.object({
        productId: Yup.string().required(),
        quantity: Yup.number().required(),
      }),
    ).required(),
    deliveryDate: Yup.string().required(),
    bcm: Yup.string().required(),
    taxed: Yup.bool().required(),
    logisticsServiceProvider: Yup.object({
      name: Yup.string().required(),
      address: Yup.string().required(),
      city: Yup.string().required(),
      postcode: Yup.string().required(),
    }),
  });

  const mutateCreate = useMutation(
    (request: ProductVioRequest) => {
      return ProductService.createVio(request);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ProductService.endpoint);

        formRef.current.reset();

        toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: t('PRODUCT.VIO_PAGE.TITLE') }));
      },

      onError: (e: any) => {
        error(t('GLOBAL.CREATED_UNSUCCESSFUL', { name: t('PRODUCT.VIO_PAGE.TITLE') }), e);
      },
    },
  );

  const handleLoadOptions = useCallback(async (searchPhrase?: string) => {
    const response = await ProductService.getList({ searchPhrase });

    const results = (response.results ?? []).map((product) => {
      return {
        value: product.id,
        label: product.name,
      };
    });

    return results;
  }, []);

  const handleSubmit = (values: ProductVioRequest) => {
    const params = {
      ...values,
      isReturn: Boolean(values.isReturn),
      taxed: !!Number(values.taxed),
    };

    return mutateCreate.mutateAsync(params);
  };

  const initialValues = {
    productStockLines: [{}],
  };

  return (
    <Form.Base
      ref={formRef}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}>
      <Form.Layout.Repeater
        label={t('PRODUCT.PLURAL')}
        source="productStockLines"
        entryName={t('PRODUCT.SINGLE')}>
        <Form.Layout.Row>
          <Form.Layout.Field key="product_name" label={t('PRODUCT.SINGLE')}>
            <Form.Input.AsyncSelect
              name="productId"
              loadOptions={handleLoadOptions}
              placeholder={t('PRODUCT.VIO_PAGE.FORM.SELECT.PLACEHOLDER')}
            />
          </Form.Layout.Field>

          <Form.Layout.Field key="product_amount" label={t('PRODUCT.LABELS.AMOUNT')}>
            <Form.Input.Number name="quantity" />
          </Form.Layout.Field>
        </Form.Layout.Row>
      </Form.Layout.Repeater>

      <Form.Layout.Field label={t('PRODUCT.LABELS.TAX')}>
        <Form.Input.Select
          name="taxed"
          defaultValue={0}
          options={[
            { key: 0, value: t('PRODUCT.VIO_PAGE.FORM.SELECT.UNTAXED').toString() },
            { key: 1, value: t('PRODUCT.VIO_PAGE.FORM.SELECT.TAXED').toString() },
          ]}
        />
      </Form.Layout.Field>

      <Form.Layout.Field label={t('PRODUCT.VIO_PAGE.FORM.DATE')}>
        <Form.Input.Date name="deliveryDate" initialDate={new Date()} />
      </Form.Layout.Field>

      <Form.Layout.Field label={t('PRODUCT.VIO_PAGE.FORM.IS_RETURN')}>
        <Form.Input.Checkbox
          name="isReturn"
          options={[
            {
              key: true,
              value: String(t('PRODUCT.VIO_PAGE.FORM.IS_RETURN_KEY')),
            },
          ]}
        />
      </Form.Layout.Field>

      <Form.Layout.Field label={t('PRODUCT.VIO_PAGE.FORM.BCM')}>
        <Form.Input.Text name="bcm" />
      </Form.Layout.Field>

      <Form.Layout.Group label={t('PRODUCT.GROUP.LOGISTICS_SERVICE_PROVIDER')}>
        <Form.Layout.Field
          key="logisticsServiceProvider.name"
          label={t('PRODUCT.LABELS.PROVIDER_NAME')}>
          <Form.Input.Text name="logisticsServiceProvider.name" />
        </Form.Layout.Field>

        <Form.Layout.Field
          key="logisticsServiceProvider.address"
          label={t('PRODUCT.LABELS.ADDRESS')}>
          <Form.Input.Text name="logisticsServiceProvider.address" />
        </Form.Layout.Field>

        <Form.Layout.Field key="logisticsServiceProvider.city" label={t('PRODUCT.LABELS.CITY')}>
          <Form.Input.Text name="logisticsServiceProvider.city" />
        </Form.Layout.Field>

        <Form.Layout.Field
          key="logisticsServiceProvider.postcode"
          label={t('PRODUCT.LABELS.ZIPCODE')}>
          <Form.Input.Text name="logisticsServiceProvider.postcode" />
        </Form.Layout.Field>
      </Form.Layout.Group>

      <Button>{t('PRODUCT.VIO_PAGE.FORM.CTA')}</Button>
    </Form.Base>
  );
};
