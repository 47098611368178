import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from '@socialbrothers/components/UI';

import styles from './BaseForm.module.scss';
import { BaseFormProps } from './BaseForm.props';

const Form = (
  {
    validationSchema,
    initialValues,
    onSubmit,
    children,
    error,
    submitLabel,
    disabled,
    ...props
  }: BaseFormProps,
  ref: any,
) => {
  const hasSetInitialValues = useRef(false);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  useImperativeHandle(ref, () => methods);

  useEffect(() => {
    if (initialValues && !hasSetInitialValues.current) {
      methods.reset(initialValues);
      hasSetInitialValues.current = true;
    }
  }, [initialValues, methods]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} {...props}>
        {error && (
          <div className={styles.BaseForm__Error}>
            <i className="fa fa-exclamation-circle" />
            {error}
          </div>
        )}

        {children}

        {!!submitLabel && (
          <Button disabled={disabled} isLoading={methods.formState.isSubmitting} type="submit">
            {submitLabel}
          </Button>
        )}
      </form>
    </FormProvider>
  );
};

export default forwardRef(Form);
