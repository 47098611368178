import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { Operator } from '@socialbrothers/constants';

import { OrderOverviewSendForm } from '@Components/forms';
import { OrderTable } from '@Components/tables';
import { IOrder, OrderDeliveryMethod, OrderStatus } from '@Services/Order';

export const OrderOverviewSendPage = () => {
  const { t } = useTranslation();
  const [selectedOrderIds, setSelectedOrderIds] = useState<string[] | null>(null);
  const [deliveryMethod, setDeliveryMethod] = useState<OrderDeliveryMethod>();

  const orderActions = [
    {
      id: 'order_actions',
      label: t('ORDER.OVERVIEW_SEND_PAGE.FORM.TITLE'),
      icon: 'truck',
      onClick: (items: IOrder[]) => {
        const orderIds = items.map((item) => item.id);

        if (items.length === 1) {
          setDeliveryMethod(items[0].deliveryMethod);
        }

        setSelectedOrderIds(orderIds);
      },
    },
  ];

  const handleClose = useCallback(() => {
    setSelectedOrderIds(null);
  }, []);

  return (
    <Page breadcrumbs={false} title={t('ORDER.OVERVIEW_SEND_PAGE.TITLE')}>
      <Card title={t('ORDER.PLURAL')}>
        <OrderTable
          type="Compose"
          status={[
            {
              operator: Operator.IS,
              value: OrderStatus.APPROVED,
            },
          ]}
          actions={orderActions}>
          <Table.Field.ResourceAction
            editConfig={(record: IOrder) => ({
              icon: 'info-circle',
              link: `/orders/${record.id}`,
            })}
          />
        </OrderTable>
        {selectedOrderIds && (
          <OrderOverviewSendForm
            onClose={handleClose}
            isVisible={!!selectedOrderIds}
            selectedOrderIds={selectedOrderIds}
            deliveryMethod={deliveryMethod}
          />
        )}
      </Card>
    </Page>
  );
};
