import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { ProductVioCreateForm } from '@Components/forms';

export const ProductVioPage = () => {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={false} title={t('PRODUCT.VIO_PAGE.TITLE')}>
      <Card title={t('PRODUCT.PLURAL')}>
        <ProductVioCreateForm />
      </Card>
    </Page>
  );
};
