import { useState } from 'react';

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  function onOpen() {
    setIsVisible(true);
  }

  function onClose() {
    setIsVisible(false);
  }

  return {
    onOpen,
    onClose,
    isVisible,
  };
};
