import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Form } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { useTheme } from '@socialbrothers/hooks';
import { Yup } from '@socialbrothers/utils';

import { AuthForgotPasswordFormProps } from './AuthForgotPasswordForm.props';

export const AuthForgotPasswordForm = ({ ...props }: AuthForgotPasswordFormProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const { authService } = useTheme();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const handleSubmit = async (values: any) => {
    try {
      await authService.forgotPassword(values.email);
      toast.success(t('AUTHENTICATION.FORGOT_PASSWORD.SUCCESS'));

      history.push('/');
    } catch (e) {
      setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
    }
  };

  return (
    <Form.Base onSubmit={handleSubmit} error={error} {...props} validationSchema={validationSchema}>
      <Form.Layout.Field translationKey="AUTHENTICATION.LABELS.EMAIL">
        <Form.Input.Text name="email" />
      </Form.Layout.Field>

      <div className="d-flex align-items-center">
        <Button block type="submit">
          {t('AUTHENTICATION.FORGOT_PASSWORD.SUBMIT_LABEL')}
        </Button>

        <Link to="" className="link ml-auto">
          {t('AUTHENTICATION.FORGOT_PASSWORD.BACK')}
        </Link>
      </div>
    </Form.Base>
  );
};
