import { useTranslation } from 'react-i18next';

import { AuthLoginForm } from '@socialbrothers/components/Forms';
import { PageUnauthenticated } from '@socialbrothers/components/Layout';
import { useTheme } from '@socialbrothers/hooks';

export const AuthLoginPage = () => {
  const { loginImage } = useTheme();
  const { t } = useTranslation();

  const initialValues = {
    email: '',
    password: '',
    rememberMe: false,
  };

  return (
    <PageUnauthenticated imageUri={loginImage} title={t('AUTHENTICATION.LOGIN.PAGE_TITLE')}>
      <AuthLoginForm initialValues={initialValues} />
    </PageUnauthenticated>
  );
};
