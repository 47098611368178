import { YupFileExtensionStatus } from '../constants';

export const getFileURL = (filename: string) => {
  return `${process.env.REACT_APP_API_URL}/${filename}`;
};

export const isFileImage = (
  path: string,
): Promise<{ path: string; status: YupFileExtensionStatus }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ path, status: YupFileExtensionStatus.OK });
    img.onerror = () => resolve({ path, status: YupFileExtensionStatus.ERROR });

    img.src = path;
  });
};

export const downloadFile = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

export const downloadFromUrl = (url: string, fileName: string) => {
  //Create XMLHTTP Request.
  var req = new XMLHttpRequest();
  req.open('GET', url, true);
  req.responseType = 'blob';
  req.onload = function () {
    //Convert the Byte Data to BLOB object.
    downloadFile(new Blob([req.response]), fileName);
  };
  req.send();
};
