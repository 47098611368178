import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { IPartner, PartnerService } from '@Services/Partner';

import { PartnerTableProps } from './PartnerTable.props';

export const PartnerTable = ({ className, children, actions }: PartnerTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IPartner> actions={actions} service={PartnerService} className={className}>
      <Table.Field.Text
        filterable
        sortable
        source="name"
        label={t('PARTNER.LABELS.NAME')}
        width={1}
      />

      <Table.Field.Text source="identifier" label={t('PARTNER.LABELS.IDENTIFIER')} width={1} />

      <Table.Field.Function
        source="postcodeRanges"
        label={t('PARTNER.LABELS.ZIPCODE_RANGES')}
        render={(record: IPartner) => {
          const zipcodeRanges = record.postcodeRanges
            .map((zipCode) => `${zipCode.rangeFrom} - ${zipCode.rangeTo}`)
            .join(', ');

          return <span className="text-small">{zipcodeRanges}</span>;
        }}
      />

      {children}
    </Table.Resource>
  );
};
