import { BaseModel } from '@socialbrothers/constants';

import { ILog } from '@Services/Log';
import { IPartner } from '@Services/Partner';

export enum OrderStatus {
  PENDING = 'Pending',
  PROCESSING = 'Processing',
  ON_HOLD = 'OnHold',
  APPROVED = 'Approved',
  BOUW_SEND = 'BouwSend',
  BOUW_APPROVED = 'BouwApproved',
  BOUW_DECLINED = 'BouwDeclined',
  ORDER_SEND = 'OrderSend',
  ORDER_DELIVERED = 'OrderDelivered',
  CANCELLED = 'Cancelled',
  REFUNDED = 'Refunded',
  FAILED = 'Failed',
  TRASH = 'Trash',
}

export enum OrderWooCommerceShippingMethod {
  FREE_SHIPPING = 'FreeShipping',
}

export enum OrderDeliveryMethod {
  BOUW = 'Bouw',
  BOUW_PICKUP = 'BouwPickup',
  POST_NL = 'PostNl',
}

export enum CombinedOrderDeliveryMethod {
  BOUW = 'Bouw',
  BOUW_PICKUP = 'BouwPickup',
}

export enum OrderDownloadOption {
  PACKING = 'Packing',
  INVOICE = 'Invoice',
}

export interface OrderApproveRequest {
  id: string;
}

export interface OrderSendRequest {
  deliveryMethod: OrderDeliveryMethod;
  orderIds: string[];
  deliveryDate: string;
  taxed: boolean;
  palletNumber?: string;
}

export interface OrderDownloadRequest {
  id: string;
  orderNr: string;
  documentType: OrderDownloadOption;
}

export interface IOrder extends BaseModel {
  orderNr: string;
  wooCommerceId: string;
  companyName: string;
  taxed: boolean;
  deliveryMethod: OrderDeliveryMethod;
  wooCommerceShippingMethod: OrderWooCommerceShippingMethod;
  address: string;
  note: string;
  status: OrderStatus;
  orderedProducts: string[];
  logs: ILog[];
  partner: IPartner;
  shippingDetails: {
    companyName: string;
  };
}
