import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import {
  IOrder,
  OrderDeliveryMethod,
  OrderStatus,
  OrderWooCommerceShippingMethod,
} from '@Services/Order';

import { OrderDetailTableProps } from './OrderDetailTable.props';

export const OrderDetailTable = ({ data }: OrderDetailTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Detail<IOrder> data={data}>
      <Table.Field.Text source="orderNr" label={t('ORDER.LABELS.ORDERNR')} />

      <Table.Field.Text source="companyName" label={t('ORDER.LABELS.COMPANY_NAME')} />

      <Table.Field.Text source="partner.name" label={t('ORDER.LABELS.PARTNER')} />

      <Table.Field.Text source="address" label={t('ORDER.LABELS.ADDRESS')} />

      <Table.Field.Enum
        name="ORDER.SHIPPING_METHOD"
        source="wooCommerceShippingMethod"
        label={t('ORDER.LABELS.SHIPPING')}
        enumeration={OrderWooCommerceShippingMethod}
      />

      <Table.Field.Enum
        name="ORDER.DELIVERY_METHOD"
        source="deliveryMethod"
        label={t('ORDER.LABELS.DELIVERY')}
        enumeration={OrderDeliveryMethod}
      />

      <Table.Field.Enum
        source="status"
        name="ORDER.STATUS"
        label={t('ORDER.LABELS.STATUS')}
        enumeration={OrderStatus}
      />

      <Table.Field.Text source="note" label={t('ORDER.LABELS.NOTE')} />

      <Table.Field.Function
        source="orderedProducts"
        label={t('ORDER.LABELS.ORDERED_PRODUCTS')}
        render={(record: IOrder) => {
          const products = record.orderedProducts.map((product, idx) => (
            <div key={idx} className="text-small">
              {product}
            </div>
          ));

          return <>{products}</>;
        }}
      />
    </Table.Detail>
  );
};
