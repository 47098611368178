import i18n from 'i18next';

import { Routes } from '@socialbrothers/constants';

import { MyAccountPage } from '@Pages/index';

export const AppRoutes: Routes = [
  {
    key: 'MyAccountPage',
    exact: true,
    path: '/account',
    component: MyAccountPage,
    icon: 'user',
    label: i18n.t('ACCOUNT.TITLE'),
  },
];
