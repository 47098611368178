import { Header, Routes, Sidebar } from '@socialbrothers/constants/interfaces';
import { AppRoutes } from '@socialbrothers/routes';
import { getRouteByKey } from '@socialbrothers/utils';

import {
  AdminDetailPage,
  AdminOverviewPage,
  MyAccountPage,
  OrderDetailPage,
  OrderOverviewApprovePage,
  OrderOverviewCompletePage,
  OrderOverviewSendPage,
  PartnerCreatePage,
  PartnerOverviewPage,
  PartnerUpdatePage,
  ProductDetailPage,
  ProductMaoPage,
  ProductOverviewPage,
  ProductStockLogPage,
  ProductVioPage,
} from '@Pages/index';
import i18n from '@Root/setupLocales';

export const routes: Routes = [
  {
    key: 'OrderOverviewApprovePage',
    label: i18n.t('ORDER.OVERVIEW_APPROVE_PAGE.TITLE'),
    path: '/orders/approve',
    component: OrderOverviewApprovePage,
    exact: true,
  },
  {
    key: 'OrderOverviewSendPage',
    label: i18n.t('ORDER.OVERVIEW_SEND_PAGE.TITLE'),
    path: '/orders/send',
    component: OrderOverviewSendPage,
    exact: true,
  },
  {
    key: 'OrderOverviewCompletePage',
    label: i18n.t('ORDER.OVERVIEW_COMPLETE_PAGE.TITLE'),
    path: '/orders/completed',
    component: OrderOverviewCompletePage,
    exact: true,
  },
  {
    key: 'OrderDetailPage',
    label: i18n.t('ORDER.DETAIL_PAGE.TITLE'),
    path: '/orders/:id',
    component: OrderDetailPage,
    exact: true,
  },
  {
    key: 'ProductOverviewPage',
    label: i18n.t('PRODUCT.OVERVIEW_PAGE.TITLE'),
    path: '/products',
    component: ProductOverviewPage,
    exact: true,
  },
  {
    key: 'ProductStockLogPage',
    label: i18n.t('LOG.PLURAL'),
    path: '/products/log',
    component: ProductStockLogPage,
    exact: true,
  },
  {
    key: 'ProductMaoPage',
    label: i18n.t('PRODUCT.MAO_PAGE.TITLE'),
    path: '/products/mao',
    component: ProductMaoPage,
    exact: true,
  },
  {
    key: 'ProductVioPage',
    label: i18n.t('PRODUCT.VIO_PAGE.TITLE'),
    path: '/products/vio',
    component: ProductVioPage,
    exact: true,
  },
  {
    key: 'ProductDetailPage',
    label: i18n.t('PRODUCT.DETAIL_PAGE.TITLE'),
    path: '/products/:id',
    component: ProductDetailPage,
    exact: true,
  },
  {
    key: 'PartnerOverviewPage',
    label: i18n.t('PARTNER.OVERVIEW_PAGE.TITLE'),
    path: '/partners',
    component: PartnerOverviewPage,
    exact: true,
  },
  {
    key: 'PartnerCreatePage',
    label: i18n.t('PARTNER.CREATE_PAGE.TITLE'),
    path: '/partners/create',
    component: PartnerCreatePage,
    exact: true,
  },
  {
    key: 'PartnerUpdatePage',
    label: i18n.t('PARTNER.UPDATE.TITLE'),
    path: '/partners/:id',
    component: PartnerUpdatePage,
    exact: true,
  },
  {
    key: 'AdminDetailPage',
    label: i18n.t('ADMIN.SINGLE'),
    path: '/administrators/:id',
    component: AdminDetailPage,
    exact: true,
  },
  {
    key: 'AdminOverviewPage',
    label: i18n.t('ADMIN.PLURAL'),
    path: '/administrators',
    icon: 'users',
    component: AdminOverviewPage,
    exact: true,
  },
  {
    key: 'MyAccountPage',
    label: i18n.t('ACCOUNT.TITLE'),
    path: '/account',
    component: MyAccountPage,
    exact: true,
  },
];

export const sidebar: Sidebar = [
  {
    title: i18n.t('GLOBAL.GENERAL'),
    routes: [
      {
        key: i18n.t('ORDER.PLURAL'),
        label: i18n.t('ORDER.PLURAL'),
        icon: 'box',
        children: [
          getRouteByKey(routes, 'OrderOverviewApprovePage'),
          getRouteByKey(routes, 'OrderOverviewSendPage'),
          getRouteByKey(routes, 'OrderOverviewCompletePage'),
        ],
      },
      {
        key: i18n.t('PRODUCT.PLURAL'),
        label: i18n.t('PRODUCT.PLURAL'),
        icon: 'wine-bottle',
        children: [
          getRouteByKey(routes, 'ProductOverviewPage'),
          getRouteByKey(routes, 'ProductStockLogPage'),
          getRouteByKey(routes, 'ProductMaoPage'),
          getRouteByKey(routes, 'ProductVioPage'),
        ],
      },
      {
        key: i18n.t('PARTNER.PLURAL'),
        label: i18n.t('PARTNER.PLURAL'),
        icon: 'truck',
        children: [
          getRouteByKey(routes, 'PartnerOverviewPage'),
          getRouteByKey(routes, 'PartnerCreatePage'),
        ],
      },
    ],
  },
  {
    title: i18n.t('GLOBAL.SETTINGS'),
    routes: [getRouteByKey(AppRoutes, 'MyAccountPage'), getRouteByKey(routes, 'AdminOverviewPage')],
  },
];

export const header: Header = [
  {
    label: i18n.t('ACCOUNT.TITLE'),
    link: '/account',
  },
  {
    label: i18n.t('ADMIN.PLURAL'),
    link: '/administrators',
  },
];
