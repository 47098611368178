import { useTranslation } from 'react-i18next';

import { AuthResetPasswordForm } from '@socialbrothers/components/Forms';
import { PageUnauthenticated } from '@socialbrothers/components/Layout';

import config from '@Config';

export const AuthResetPasswordPage = () => {
  const { t } = useTranslation();

  const initialValues = {
    password: '',
    passwordConfirmation: '',
  };

  return (
    <PageUnauthenticated
      imageUri={config.forgotPasswordImage}
      title={t('AUTHENTICATION.RESET_PASSWORD.PAGE_TITLE')}>
      <AuthResetPasswordForm initialValues={initialValues} />
    </PageUnauthenticated>
  );
};
