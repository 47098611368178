import cn from 'classnames';
import { get } from 'lodash';
import { Children, cloneElement } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';

import styles from './Repeater.module.scss';
import { RepeaterProps } from './Repeater.props';

const Repeater = ({ children, label, entryName, className, source, ...props }: RepeaterProps) => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: source,
  });

  const alterSourceProp = (element: JSX.Element, index: number, field: any) => {
    return cloneElement(element, {
      name: `${source}.${index}.${element.props.name}`,
      defaultValue: field[element.props.name],
    });
  };

  const alterInputProp = (
    element: JSX.Element | JSX.Element[],
    index: number,
    field: any,
  ): JSX.Element | JSX.Element[] => {
    if (Array.isArray(element)) {
      return element.map((child) => {
        if (!child.props.children && child.props.name) {
          return alterSourceProp(child, index, field);
        } else {
          return cloneElement(child, {
            children: alterInputProp(child.props.children, index, field),
          });
        }
      });
    } else {
      if (!element.props.children && element.props.name) {
        return alterSourceProp(element, index, field);
      } else {
        return cloneElement(element, {
          children: alterInputProp(element.props.children, index, field),
        });
      }
    }
  };

  const cloneChildren = (index: number, field: any) => {
    return Children.map(children, (child) => {
      return alterInputProp(child, index, field);
    });
  };

  const getGroup = (index: number, field: any) => {
    return (
      <div className={styles.Field} key={field.id}>
        <div className={styles.Field__Header}>
          <div className={styles.Field__Title}>{`${entryName} ${index + 1}`}</div>

          <div onClick={() => remove(index)}>
            <Icon icon="trash-alt" type="regular" className={styles.Field__Delete} />
          </div>
        </div>

        {cloneChildren(index, field)}
      </div>
    );
  };

  const errorMessage = get(formState.errors, source)?.message;

  return (
    <div
      className={cn(styles.Repeater, className, {
        [styles['Repeater--Error']]: !!errorMessage,
      })}
      {...props}>
      <fieldset className={styles.Fieldset}>
        <legend>{label}</legend>

        <div className={styles.Fields}>
          {fields.map((field, index) => {
            return getGroup(index, field);
          })}

          <div className={styles.Field}>
            <Button
              color={Color.SECONDARY}
              className={styles.Add}
              icon="plus"
              type="button"
              onClick={() => append({})}>
              {t('FORMS.REPEATER.ADD_ENTRY', { entryName })}
            </Button>
          </div>
        </div>
      </fieldset>
      {!!errorMessage && <div className={styles.Repeater__Error}>{errorMessage}</div>}
    </div>
  );
};

export default Repeater;
