import { AccountService, AuthService } from './services';

const config = {
  logo: require('@Assets/images/logo.png').default,
  loginImage: require('@Assets/images/background.jpg').default,
  forgotPasswordImage: require('@Assets/images/background.jpg').default,
  passwordRegex:
    /(?=^.{8,24}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_\-+}{&;:;'?/&.,<>])(?!.*\s).*$/g, // One lowercase, one uppercase, one digit, one special char, length 8 - 24
  authService: AuthService,
  accountService: AccountService,
  enableTwoFactorAuthentication: false,
};

export default config;
