import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { LogTable } from '@Components/tables';
import { ILogCategory } from '@Services/Log';

export const ProductStockLogPage = () => {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={false} title={t('LOG.PLURAL')}>
      <Card title={t('LOG.NAME')}>
        <LogTable category={ILogCategory.STOCK} />
      </Card>
    </Page>
  );
};
