import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Operator } from '@socialbrothers/constants';

import { ILog, LogService } from '@Services/Log';
import { getIcariusProductUrl } from '@Utils/StringUtils';

import { LogTableProps } from './LogTable.props';

export const LogTable = ({ category, filters, ...props }: LogTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<ILog>
      service={LogService}
      filters={
        !!category
          ? {
              ...filters,
              category: [
                {
                  operator: Operator.IS,
                  value: category,
                },
              ],
            }
          : filters
      }
      {...props}>
      <Table.Field.Date sortable width={1} source="createdAt" label={t('LOG.LABELS.CREATED_AT')} />

      <Table.Field.Function
        width={1}
        filterable
        source="product.name"
        label={t('PRODUCT.LABELS.NAME')}
        render={(item: ILog) => (
          <a
            className="link color-primary"
            target="_blank"
            href={getIcariusProductUrl(item.productId)}
            rel="noreferrer">
            {item.name}
          </a>
        )}
      />

      <Table.Field.Function
        source="text"
        label={t('LOG.LABELS.TEXT')}
        render={(record: ILog) => {
          return (
            <div key={record.id} className="text-small">
              {record.text}
            </div>
          );
        }}
      />

      <Table.Field.ResourceAction
        editConfig={(record: ILog) => ({
          icon: 'info-circle',
          link: `/products/${record.productId}`,
        })}
      />
    </Table.Resource>
  );
};
