import { BaseModel, ListResponse, Operator, RequestConfig } from '@socialbrothers/constants';
import { downloadFile } from '@socialbrothers/utils/FileUtils';

import { getCurrentDateTime } from '@Utils/DateUtils';

import { Service } from './Service';

export interface ResourceInterface<T = any> {
  endpoint: string;
  getOne: (id: string) => Promise<T>;
  getList: (
    config?: RequestConfig<T>,
    extra?: { [key: string]: string | number },
  ) => Promise<ListResponse<T>>;
  export: (config?: RequestConfig<T>, extra?: { [key: string]: string | number }) => Promise<void>;
  create: (values: Partial<T>) => Promise<T>;
  update: (id: string, values: any) => Promise<T>;
  delete: (id: string) => Promise<T>;
  validate: (field: string, value: any) => Promise<boolean>;
}
export class ResourceService<T extends BaseModel> extends Service implements ResourceInterface<T> {
  getOne = async (id: string) => {
    const response = await this.client.get<T>(`${this.endpoint}/${id}`);

    return response.data;
  };

  getList = async (config?: RequestConfig<T>, extra?: { [key: string]: string | number }) => {
    const params = {
      ...config,
      ...extra,
    };

    if (config?.filtersJson) {
      params.filtersJson = config.filtersJson;
    }

    const response = await this.client.get<ListResponse<T>>(this.endpoint, params);

    return response.data;
  };

  export = async (config?: RequestConfig<T>, extra?: any) => {
    const params = {
      ...config,
      ...extra,
      filtersJson: config?.filtersJson || null,
      export: 1,
    };

    const response = await this.client.get<ListResponse<T[]>>(this.endpoint, params);

    downloadFile(
      new Blob([response.data as any], { type: 'application/octet-stream' }),
      `export_${getCurrentDateTime()}.csv`,
    );
  };

  getMany = (ids: string[], filtersJson?: RequestConfig<T>) => {
    return this.getList({
      ...filtersJson,
      filtersJson: {
        ...filtersJson?.filtersJson,
        id: {
          operator: Operator.IS,
          value: ids,
        },
      },
    } as any);
  };

  create = async (data: Partial<T>) => {
    const response = await this.client.post<T>(this.endpoint, data);

    return response.data;
  };

  update = async (id: string, data: Partial<T>) => {
    const response = await this.client.put<T>(`${this.endpoint}/${id}`, {
      id,
      ...data,
    });

    return response.data;
  };

  delete = async (id: string) => {
    const response = await this.client.delete<T>(`${this.endpoint}/${id}`);

    return response.data;
  };

  deleteMany = (ids: string[]) => {
    return Promise.all(
      ids.map((id) => {
        return this.delete(id);
      }),
    );
  };

  deleteFile = async (id: string, field: string) => {
    const response = await this.client.delete(`${this.endpoint}/${id}/${field}`);

    return response.data;
  };

  validate = async (field: string, value: any) => {
    const params = {
      field: field,
      value: value,
    };

    const response = await this.client.post<{ isValid: boolean }>(
      `${this.endpoint}/validate`,
      params,
    );

    return response.data.isValid;
  };
}
