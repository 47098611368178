import cn from 'classnames';
import { useRef } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { Icon } from '@socialbrothers/components/UI';
import { useOnClickOutside, useToggle } from '@socialbrothers/hooks';

import styles from './DropdownButton.module.scss';
import { DropdownButtonProps } from './DropdownButton.props';

export const DropdownButton = ({ children, items, className, ...props }: DropdownButtonProps) => {
  const { toggle, isToggle, setToggle } = useToggle(false);
  var location = useLocation();
  const ref = useRef<any>();

  useOnClickOutside(ref, () => {
    setToggle(false);
  });

  const isActive = (path: string) =>
    (!!matchPath(location.pathname, {
      path: path,
      exact: false,
      strict: false,
    }) &&
      path !== '/') ||
    path === location.pathname;

  return (
    <div ref={ref} className={styles.DropdownButton} {...props}>
      <div onClick={toggle} className={styles.DropdownButton__Button}>
        {children}
        <Icon className={styles.DropdownButton__Chevron} type="regular" icon="chevron-down" />
      </div>

      {isToggle && (
        <div className={styles.DropdownButton__Dropdown}>
          {items.map((item, idx) => {
            return item.onPress ? (
              <span
                key={idx}
                className={cn(
                  styles.DropdownButton__Item,
                  styles['DropdownButton__Item--Clickable'],
                )}
                onClick={item.onPress}>
                {item.label}
              </span>
            ) : (
              <Link
                to={item.link as string}
                className={cn(styles.DropdownButton__Item, {
                  [styles.isActive]: isActive(item.link as string),
                })}
                key={idx}>
                {item.label}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};
