import ADMIN from './admin.json';
import ENUM from './enum.json';
import GLOBAL from './global.json';
import LOG from './log.json';
import ORDER from './order.json';
import PARTNER from './partner.json';
import PRODUCT from './product.json';

const data = {
  translation: {
    GLOBAL,
    ENUM,
    ADMIN,
    ORDER,
    PARTNER,
    PRODUCT,
    LOG,
  },
};

export default data;
