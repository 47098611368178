import { ResourceService } from '@socialbrothers/services';
import { downloadFile, getTranslation } from '@socialbrothers/utils';

import { ApiService } from '@Services/index';
import {
  IOrder,
  OrderApproveRequest,
  OrderDownloadRequest,
  OrderSendRequest,
} from '@Services/Order';

export class OrderServiceImplementation extends ResourceService<IOrder> {
  approve = async (request: OrderApproveRequest) => {
    await this.client.post(`${this.endpoint}/${request.id}/approve`, {
      id: request.id,
    });
  };

  sendDelivery = async (request: OrderSendRequest) => {
    await this.client.post(`${this.endpoint}/send`, request);
  };

  getDownload = async (request: OrderDownloadRequest) => {
    const response = await this.client.post(
      `${this.endpoint}/${request.id}/download`,
      {
        DocumentType: request.documentType,
      },
      {
        responseType: 'blob',
      },
    );

    const filename = `${getTranslation(
      `ENUM.ORDER.DOWNLOAD_OPTION.${request.documentType}`,
    )} ${getTranslation('ORDER.SINGLE')?.toLowerCase()} #${request.orderNr}`;

    downloadFile(new Blob([response.data as any], { type: 'application/pdf' }), filename);
  };
}

export const OrderService = new OrderServiceImplementation(ApiService, '/admin/orders');
