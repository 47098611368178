import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Spinner } from '@socialbrothers/components/UI';

import { useProductGetPalletOccupation } from '@Hooks/Product/useProductGetPalletOccupation';
import { IPallet } from '@Services/Product';

export const ProductPalletTable = () => {
  const { t } = useTranslation();

  const { data: palletData, isLoading: isPalletDataLoading } = useProductGetPalletOccupation();

  if (isPalletDataLoading || !palletData) {
    return <Spinner />;
  }

  return (
    <Table.Detail<IPallet> data={palletData}>
      <Table.Field.Function
        source="palletOccupation"
        label={t('PRODUCT.LABELS.PALLET_OCCUPATION')}
        render={(item: IPallet) => <div>{Math.ceil(item.palletOccupation)}</div>}
      />

      <Table.Field.Number source="stock" label={t('PRODUCT.LABELS.BOTTLE_AMOUNT')} />
    </Table.Detail>
  );
};
