import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { LogDetailTable, ProductDetailTable } from '@Components/tables';
import { IProduct, ProductService } from '@Services/Product';

export const ProductDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useResource<IProduct>(ProductService, id);

  if (!data) {
    return null;
  }

  return (
    <Page breadcrumbs={false} title={data.name}>
      <Card title={t('PRODUCT.DETAIL_PAGE.TITLE')}>
        <ProductDetailTable data={data} />
      </Card>

      <Card title={t('PRODUCT.DETAIL_PAGE.LOG.TITLE')}>
        <LogDetailTable logs={data.logs} />
      </Card>
    </Page>
  );
};
