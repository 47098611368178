import { useTranslation } from 'react-i18next';

import { useTheme } from '@socialbrothers/hooks';

import styles from './PageUnauthenticated.module.scss';
import { PageUnauthenticatedProps } from './PageUnauthenticated.props';

export const PageUnauthenticated = ({
  title,
  description,
  children,
  imageUri,
  ...props
}: PageUnauthenticatedProps) => {
  const { t } = useTranslation();
  const { logo } = useTheme();

  return (
    <div className={styles.PageUnauthenticated} {...props}>
      <div className={styles.Image}>
        <img src={imageUri} alt="" />
      </div>

      <div className={styles.Card}>
        {logo && <img src={logo} className={styles.Logo} alt={t('GLOBAL.SITE_NAME')} />}

        <div className={styles.Heading}>
          <h1>{title}</h1>
          {description && <p>{description}</p>}
        </div>

        {children}
      </div>
    </div>
  );
};
