import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { Operator } from '@socialbrothers/constants';

import { ResourceButton } from '@Components/container';
import { OrderTable } from '@Components/tables';
import { IOrder, OrderDownloadOption, OrderStatus } from '@Services/Order';

export const OrderOverviewCompletePage = () => {
  const { t } = useTranslation();

  const statuses = [
    OrderStatus.PENDING,
    OrderStatus.PROCESSING,
    OrderStatus.ON_HOLD,
    OrderStatus.APPROVED,
    OrderStatus.CANCELLED,
    OrderStatus.REFUNDED,
    OrderStatus.FAILED,
    OrderStatus.TRASH,
  ];

  const filteredStatus = statuses.map((status) => {
    return {
      operator: Operator.IS_NOT,
      value: status,
    };
  });

  return (
    <Page breadcrumbs={false} title={t('ORDER.OVERVIEW_COMPLETE_PAGE.TITLE')}>
      <Card title={t('ORDER.PLURAL')}>
        <OrderTable status={filteredStatus} type="Finished">
          <Table.Field.ResourceAction
            editConfig={(record: IOrder) => ({
              icon: 'info-circle',
              link: `/orders/${record.id}`,
            })}>
            <ResourceButton icon="file-invoice-dollar" documentType={OrderDownloadOption.INVOICE} />

            <ResourceButton icon="file-alt" documentType={OrderDownloadOption.PACKING} />
          </Table.Field.ResourceAction>
        </OrderTable>
      </Card>
    </Page>
  );
};
