import i18next from 'i18next';

import { Routes } from '@socialbrothers/constants';
import {
  AuthForgotPasswordPage,
  AuthLoginPage,
  AuthResetPasswordPage,
} from '@socialbrothers/pages';

export const AuthRoutes: Routes = [
  {
    key: 'AuthLoginPage',
    label: 'Login',
    icon: 'user',
    path: '/',
    component: AuthLoginPage,
    exact: true,
  },
  {
    key: 'AuthForgotPasswordPage',
    label: i18next.t('FORGOT_PASSWORD.LABEL'),
    icon: 'user',
    path: '/forgot-password',
    component: AuthForgotPasswordPage,
    exact: true,
  },
  {
    key: 'AuthResetPasswordPage',
    label: i18next.t('RESET_PASSWORD.LABEL'),
    icon: 'user',
    path: '/reset-password',
    component: AuthResetPasswordPage,
    exact: true,
  },
];
