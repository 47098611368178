import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export const useSentry = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        environment: process.env.NODE_ENV,
      });
    }
  }, []);
};
