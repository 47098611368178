import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Form } from '@socialbrothers/components/Containers';

import { PartnerService } from '@Services/Partner';

export const PartnerForm = () => {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    identifier: Yup.string().required(),
    postcodeRanges: Yup.array(
      Yup.object({
        rangeFrom: Yup.number()
          .required()
          .min(1000, t('PARTNER.YUP.NUMBER.INVALID_ZIPCODE'))
          .max(9999, t('PARTNER.YUP.NUMBER.INVALID_ZIPCODE'))
          .integer(),
        rangeTo: Yup.number()
          .required()
          .min(1000, t('PARTNER.YUP.NUMBER.INVALID_ZIPCODE'))
          .max(9999, t('PARTNER.YUP.NUMBER.INVALID_ZIPCODE'))
          .integer(),
      }),
    ).required(),
    address: Yup.string().required(),
    postcode: Yup.string().required(),
    city: Yup.string().required(),
    email: Yup.string().email().required(),
    phoneNumber: Yup.string().phone().required(),
    languageCode: Yup.string().required(),
  });

  return (
    <Form.Resource
      service={PartnerService}
      label={t('PARTNER.SINGLE')}
      validationSchema={validationSchema}
      id={id}>
      <Form.Layout.Field key="name" label={t('PARTNER.LABELS.NAME')}>
        <Form.Input.Text name="name" />
      </Form.Layout.Field>

      <Form.Layout.Field key="identifier" label={t('PARTNER.LABELS.IDENTIFIER')}>
        <Form.Input.Text name="identifier" />
      </Form.Layout.Field>

      <Form.Layout.Repeater
        label={t('PARTNER.LABELS.ZIPCODE_RANGES')}
        source="postcodeRanges"
        entryName={t('PARTNER.LABELS.ZIPCODE')}>
        <Form.Layout.Row>
          <Form.Layout.Field key="rangeFrom" label={t('PARTNER.LABELS.RANGE_FROM')}>
            <Form.Input.Number name="rangeFrom" min={1000} max={9999} step={1} />
          </Form.Layout.Field>

          <Form.Layout.Field key="rangeTo" label={t('PARTNER.LABELS.RANGE_TO')}>
            <Form.Input.Number name="rangeTo" min={1000} max={9999} step={1} />
          </Form.Layout.Field>
        </Form.Layout.Row>
      </Form.Layout.Repeater>

      <Form.Layout.Group label={t('PARTNER.GROUP.ADDRESS')}>
        <Form.Layout.Field key="address" label={t('PARTNER.LABELS.ADDRESS')}>
          <Form.Input.Text name="address" />
        </Form.Layout.Field>

        <Form.Layout.Field key="postcode" label={t('PARTNER.LABELS.ZIPCODE')}>
          <Form.Input.Text name="postcode" />
        </Form.Layout.Field>

        <Form.Layout.Field key="city" label={t('PARTNER.LABELS.CITY')}>
          <Form.Input.Text name="city" />
        </Form.Layout.Field>

        <Form.Layout.Field key="email" label={t('PARTNER.LABELS.EMAIL')}>
          <Form.Input.Text name="email" />
        </Form.Layout.Field>

        <Form.Layout.Field key="phoneNumber" label={t('PARTNER.LABELS.PHONE_NUMBER')}>
          <Form.Input.Text name="phoneNumber" />
        </Form.Layout.Field>

        <Form.Layout.Field key="languageCode" label={t('PARTNER.LABELS.LANGUAGE_CODE')}>
          <Form.Input.Text name="languageCode" />
        </Form.Layout.Field>
      </Form.Layout.Group>
    </Form.Resource>
  );
};
