import React from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { MyAccountForm } from '@Components/forms';

export const MyAccountPage = () => {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={false} title={t('ACCOUNT.TITLE')}>
      <Card title={t('ACCOUNT.TITLE')}>
        <MyAccountForm />
      </Card>
    </Page>
  );
};
