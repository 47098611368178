import { captureException } from '@sentry/react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopup } from '@socialbrothers/components/Containers';
import { Color } from '@socialbrothers/constants';

import { OrderApproveRequest, OrderService } from '@Services/Order';

import { OrderOverviewApproveFormProps } from './OrderOverviewApproveForm.props';
export const OrderOverviewApproveForm = ({ record }: OrderOverviewApproveFormProps) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const mutateUpdate = useMutation(
    (request: OrderApproveRequest) => {
      return OrderService.approve(request);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(OrderService.endpoint);
        toast.success(
          t('GLOBAL.UPDATED_SUCCESSFUL', {
            name: `${t('ORDER.SINGLE')} ${record.orderNr}`,
          }),
        );
      },
      onError: (e: any) => {
        captureException(e);

        toast.error(
          t('GLOBAL.UPDATED_UNSUCCESSFUL', {
            name: `${t('ORDER.SINGLE')} ${record.orderNr}`,
          }),
        );
      },
    },
  );

  return (
    <ButtonWithPopup
      button={{
        color: Color.PRIMARY,
        icon: 'check',
      }}
      submit={{
        label: t('ORDER.OVERVIEW_APPROVE_PAGE.FORM.CTA'),
        onClick: () => {
          return mutateUpdate.mutateAsync(record);
        },
      }}
      popup={{
        title: t('ORDER.OVERVIEW_APPROVE_PAGE.FORM.TITLE'),
      }}>
      <p>
        <Trans
          i18nKey="ORDER.OVERVIEW_APPROVE_PAGE.FORM.DESCRIPTION"
          values={{ orderNr: record.orderNr }}
          components={{
            bold: <span className="font-weight-bold" />,
          }}
        />
      </p>
    </ButtonWithPopup>
  );
};
