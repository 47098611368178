import { ResourceService } from '@socialbrothers/services';

import { ApiService } from '@Services/index';
import { IPallet, IProduct, ProductMaoRequest, ProductVioRequest } from '@Services/Product';

export class ProductServiceImplementation extends ResourceService<IProduct> {
  createMao = async (request: ProductMaoRequest) => {
    await this.client.post<void>(`${this.endpoint}/${request.id}/mao`);
  };

  createVio = async (request: ProductVioRequest) => {
    await this.client.post<void>(`${this.endpoint}/vio`, request);
  };

  getPalletOccupation = async () => {
    const response = await this.client.get<IPallet>(`${this.endpoint}/pallet`);

    return response.data;
  };
}

export const ProductService = new ProductServiceImplementation(ApiService, '/admin/products');
