import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Form } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { ApiError } from '@socialbrothers/constants';
import { useTheme } from '@socialbrothers/hooks';

import { useStores } from '@Hooks/index';

import { AuthLoginFormProps, AuthLoginFormValues } from './AuthLoginForm.props';

export const AuthLoginForm = ({ ...props }: AuthLoginFormProps) => {
  const { t } = useTranslation();
  const { authService } = useTheme();
  const [error, setError] = useState('');
  const { AuthStore } = useStores();

  const validationSchemaLogin = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  const handleLogin = async (values: AuthLoginFormValues) => {
    try {
      await authService.login(values);
      AuthStore.setIsAuthenticated(true);

      setError('');
    } catch (e) {
      if (e.response?.data?.code === ApiError.INVALID_CREDENTIALS) {
        setError(t('VALIDATION.LOGIN.INCORRECT_CREDENTIALS'));
      } else {
        setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
      }
    }
  };

  return (
    <Form.Base
      onSubmit={handleLogin}
      error={error}
      validationSchema={validationSchemaLogin}
      {...props}>
      <Form.Layout.Field label={t('AUTHENTICATION.LABELS.EMAIL')}>
        <Form.Input.Text name="email" />
      </Form.Layout.Field>

      <Form.Layout.Field label={t('AUTHENTICATION.LABELS.PASSWORD')}>
        <Form.Input.Text type="password" name="password" />
      </Form.Layout.Field>

      <div className="d-flex align-items-center">
        <Button type="submit">{t('AUTHENTICATION.LOGIN.SUBMIT_LABEL')}</Button>

        <Link className="link ml-auto" to="forgot-password">
          {t('AUTHENTICATION.LOGIN.FORGOT_PASSWORD')}
        </Link>
      </div>
    </Form.Base>
  );
};
