import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Operator } from '@socialbrothers/constants';

import { ProductMaoConfirmForm } from '@Components/forms';
import { IProduct, ProductService, ProductStatus } from '@Services/Product';
import { getIcariusProductUrl } from '@Utils/StringUtils';

import { ProductTableProps } from './ProductTable.props';

export const ProductTable = ({ status, filters, ...props }: ProductTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IProduct>
      searchable
      service={ProductService}
      filters={
        !!status
          ? {
              ...filters,
              status: [
                {
                  operator: Operator.IS,
                  value: status,
                },
              ],
            }
          : filters
      }
      {...props}>
      <Table.Field.Function
        sortable
        filterable
        source="name"
        label={t('PRODUCT.LABELS.NAME')}
        render={(item: IProduct) => (
          <a
            className="link color-primary"
            target="_blank"
            href={getIcariusProductUrl(item.slug)}
            rel="noreferrer">
            {item.name}
          </a>
        )}
      />

      <Table.Field.Text
        sortable
        filterable
        width={1}
        source="sku"
        label={t('PRODUCT.LABELS.SKU')}
      />

      <Table.Field.Price sortable width={1} source="price" label={t('PRODUCT.LABELS.PRICE')} />

      <Table.Field.Number
        sortable
        filterable
        width={1}
        source="stock"
        label={t('PRODUCT.LABELS.STOCK')}
      />

      <Table.Field.Enum
        sortable
        filterable={!status}
        width={1}
        source="status"
        name="PRODUCT.STATUS"
        enumeration={ProductStatus}
        label={t('PRODUCT.LABELS.STATUS')}
      />

      <Table.Field.ResourceAction
        editConfig={(record: IProduct) => ({
          icon: 'info-circle',
          link: `/products/${record.id}`,
        })}>
        {status === ProductStatus.CREATED && <ProductMaoConfirmForm />}
      </Table.Field.ResourceAction>
    </Table.Resource>
  );
};
