import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Form } from '@socialbrothers/components/Containers';
import { Account } from '@socialbrothers/constants';
import { useTheme } from '@socialbrothers/hooks';

import config from '@Config';

import { MyAccountFormValues } from './MyAccountForm.props';

export const MyAccountForm = ({ ...props }) => {
  const { t } = useTranslation();
  const { accountService } = useTheme();
  const queryClient = useQueryClient();
  const [changePassword, setChangePassword] = useState(false);
  const user = useQuery(['users', 'me'], accountService.me);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().when('password_change', {
      is: 'true',
      then: (s) => s.matches(config.passwordRegex).required(),
    }),
    passwordConfirmation: Yup.string().when('password_change', {
      is: 'true',
      then: (s) =>
        s
          .equals([Yup.ref('password'), null], t('VALIDATION.COMMON.PASSWORDS_DONT_MATCH'))
          .required(),
    }),
  });

  const mutateUpdateMe = useMutation(
    (params: Account) => {
      return accountService.updateMe(params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(accountService.endpoint);

        toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('ACCOUNT.YOUR_ACCOUNT') }));
      },
      onError: () => {
        toast.error(t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: t('ACCOUNT.YOUR_ACCOUNT') }));
      },
    },
  );

  const handleSubmit = (values: MyAccountFormValues) => {
    const params = {
      ...values,
      id: user.data?.id as string,
      password: values.password || undefined,
    };

    return mutateUpdateMe.mutateAsync(params);
  };

  return (
    <Form.Base
      onSubmit={handleSubmit}
      submitLabel={t('GLOBAL.SAVE')}
      validationSchema={validationSchema}
      initialValues={user.data}
      {...props}>
      <Form.Layout.Field label={t('ACCOUNT.LABELS.EMAIL')}>
        <Form.Input.Text name="email" />
      </Form.Layout.Field>

      <Form.Layout.Field
        label={t('ACCOUNT.LABELS.PASSWORD_CHANGE')}
        onChange={(value) => {
          setChangePassword(value);
        }}>
        <Form.Input.Checkbox
          name="password_change"
          options={[{ key: 'true', value: String(t('ACCOUNT.LABELS.PASSWORD_CHANGE')) }]}
        />
      </Form.Layout.Field>

      {changePassword && (
        <>
          <Form.Layout.Field translationKey="ACCOUNT.LABELS.PASSWORD">
            <Form.Input.Text name="password" type="password" />
          </Form.Layout.Field>

          <Form.Layout.Field translationKey="ACCOUNT.LABELS.PASSWORD_CONFIRMATION">
            <Form.Input.Text type="password" name="passwordConfirmation" />
          </Form.Layout.Field>
        </>
      )}
    </Form.Base>
  );
};
