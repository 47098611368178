import { BaseModel } from '@socialbrothers/constants';

export enum ILogCategory {
  STOCK = 'Stock',
  STATUS = 'Status',
  DELIVERY = 'Delivery',
}

export interface ILog extends BaseModel {
  productId: string;
  name: string;
  sku: string;
  text: string;
  category: ILogCategory;
  createdAt: string;
}
