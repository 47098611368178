import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { PartnerTable } from '@Components/tables';
import { IPartner } from '@Services/Partner';

export const PartnerOverviewPage = () => {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={false} title={t('PARTNER.OVERVIEW_PAGE.TITLE')}>
      <Card title={t('PARTNER.PLURAL')}>
        <PartnerTable>
          <Table.Field.ResourceAction
            editConfig={(record: IPartner) => ({
              link: `/partners/${record.id}`,
            })}
            deleteConfig={(record: IPartner) => ({
              name: record.name,
            })}
          />
        </PartnerTable>
      </Card>
    </Page>
  );
};
