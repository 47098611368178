import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { LogDetailTable, OrderDetailTable } from '@Components/tables';
import { IOrder, OrderService } from '@Services/Order';

export const OrderDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useResource<IOrder>(OrderService, id);

  if (!data) {
    return null;
  }

  return (
    <Page breadcrumbs={false} title={`${t('ORDER.SINGLE')} #${data.orderNr}`}>
      <Card title={t('ORDER.DETAIL_PAGE.TITLE')}>
        <OrderDetailTable data={data} />
      </Card>

      <Card title={t('ORDER.DETAIL_PAGE.LOG.TITLE')}>
        <LogDetailTable logs={data.logs} />
      </Card>
    </Page>
  );
};
