import { useState } from 'react';

export const useToggle = (initialValue: boolean) => {
  const [isToggle, setIsToggle] = useState(initialValue);

  const toggle = () => setIsToggle((value) => !value);
  const setToggle = (value: boolean) => setIsToggle(value);

  return {
    toggle,
    isToggle,
    setToggle,
  };
};
