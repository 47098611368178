import cn from 'classnames';

import styles from './Spinner.module.scss';
import { SpinnerProps } from './Spinner.props';

export const Spinner: React.FC<SpinnerProps> = ({ color, size = 40, className }) => {
  const dimension = `${size}px`;

  return (
    <div className={cn([styles.Spinner, className])}>
      <div
        style={{ width: dimension, height: dimension, borderWidth: size / 6 }}
        className={cn(styles.Loader, {
          [styles[`Loader--${color}`]]: !!color,
        })}
      />
    </div>
  );
};
