import { observer } from 'mobx-react-lite';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';

import { AppRoutes, AuthRoutes } from '@socialbrothers/routes';
import { getRoutesFromConfig } from '@socialbrothers/utils';

import { useStores } from '@Hooks/index';

import { routes } from './routes';

export const RootNavigator = observer(() => {
  const { AuthStore } = useStores();

  const allRoutes = [...(!AuthStore.isAuthenticated ? AuthRoutes : [...AppRoutes, ...routes])];

  return (
    <Router>
      <Switch>
        {getRoutesFromConfig(allRoutes)}

        {AuthStore.isAuthenticated ? <Redirect to="/orders/approve" /> : <Redirect to="/" />}
      </Switch>
    </Router>
  );
});
