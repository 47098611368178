import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import BaseTable from '@socialbrothers/components/Containers/Table/BaseTable/BaseTable';

import { LogDetailTableProps } from './LogDetailTable.props';

export const LogDetailTable = ({ logs }: LogDetailTableProps) => {
  const { t } = useTranslation();

  if (!logs || logs.length === 0) {
    return <p>{t('LOG.EMPTY')}</p>;
  }

  return (
    <BaseTable data={logs}>
      <Table.Field.Date showTime source="createdAt" label={t('LOG.LABELS.CREATED_AT')} width={1} />

      <Table.Field.Text source="log" label={t('LOG.LABELS.LOG')} />
    </BaseTable>
  );
};
