import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';

import styles from './AsyncSelectInput.module.scss';
import { AsyncSelectInputProps } from './AsyncSelectInput.props';

const AsyncSelectInput = ({
  name,
  options,
  loadOptions,
  disabled,
  placeholder,
}: AsyncSelectInputProps) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();
  const value = watch(name);

  const selectOptions =
    options?.reduce<Array<{ [key: string]: any }>>((total, item) => {
      total.push({
        value: item.key,
        label: item.value,
        isDisabled: item.disabled,
      });

      return total;
    }, []) ?? [];

  const getOptionByKey = (key: string) => {
    return selectOptions.find((item) => {
      return String(item.value) === String(key);
    });
  };

  const customStyles = {
    option: (base: any) => ({
      ...base,
      fontSize: '14px',
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 1001,
    }),
    control: (base: any) => ({
      ...base,
      paddingLeft: 8,
      height: 48,
      minHeight: 48,
    }),
  };

  return (
    <Controller
      defaultValue=""
      name={name}
      control={control}
      render={({ onChange }) => {
        return (
          <AsyncSelect
            onChange={(newValue) => {
              onChange(newValue?.value);
            }}
            loadOptions={loadOptions}
            styles={customStyles}
            value={getOptionByKey(value)}
            menuPortalTarget={document.body}
            className={styles.AsyncSelectInput__Select}
            options={selectOptions}
            isDisabled={disabled}
            placeholder={!!placeholder ? placeholder : t('FORMS.SELECT.PLACEHOLDER')}
          />
        );
      }}
    />
  );
};

export default AsyncSelectInput;
