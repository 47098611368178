import AsyncSelectInput from './AsyncSelectInput/AsyncSelectInput';
import BaseForm from './BaseForm/BaseForm';
import CheckboxInput from './CheckboxInput/CheckboxInput';
import ChildResourceForm from './ChildResourceForm/ChildResourceForm';
import DateInput from './DateInput/DateInput';
import Field from './Field/Field';
import FileInput from './FileInput/FileInput';
import Group from './Group/Group';
import HiddenInput from './HiddenInput/HiddenInput';
import MultilineInput from './MultilineInput/MultilineInput';
import NumberInput from './NumberInput/NumberInput';
import RadioInput from './RadioInput/RadioInput';
import Repeater from './Repeater/Repeater';
import ResourceForm from './ResourceForm/ResourceForm';
import RichTextInput from './RichTextInput/RichTextInput';
import Row from './Row/Row';
import SelectInput from './SelectInput/SelectInput';
import TextInput from './TextInput/TextInput';

export const Form = {
  Layout: {
    Group,
    Row,
    Field,
    Repeater,
  },
  Input: {
    Select: SelectInput,
    AsyncSelect: AsyncSelectInput,
    Text: TextInput,
    Hidden: HiddenInput,
    Number: NumberInput,
    Radio: RadioInput,
    Checkbox: CheckboxInput,
    Multiline: MultilineInput,
    Date: DateInput,
    File: FileInput,
    RichText: RichTextInput,
  },
  Resource: ResourceForm,
  ChildResource: ChildResourceForm,
  Base: BaseForm,
};
