import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { AdminTable } from '@socialbrothers/components/Tables';
import { Button, Card } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';

export const AdminOverviewPage = () => {
  const { t } = useTranslation();

  const buttons = (
    <Button icon="plus" to="/administrators/create" color={Color.SECONDARY}>
      {t('GLOBAL.CREATE_MODEL', { name: t('ADMIN.SINGLE') })}
    </Button>
  );

  return (
    <Page breadcrumbs={false} title={t('ADMIN.PLURAL')} buttons={buttons}>
      <Card title={t('ADMIN.PLURAL')}>
        <AdminTable />
      </Card>
    </Page>
  );
};
