import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopup } from '@socialbrothers/components/Containers';
import { Color } from '@socialbrothers/constants';

import { useError } from '@Hooks/useError';
import { ProductMaoRequest, ProductService } from '@Services/Product';

import { ProductMaoConfirmFormProps } from './ProductMaoConfirmForm.props';

export const ProductMaoConfirmForm = ({ record }: ProductMaoConfirmFormProps) => {
  const { t } = useTranslation();
  const error = useError();
  const queryClient = useQueryClient();

  const mutateUpdate = useMutation(
    (request: ProductMaoRequest) => {
      return ProductService.createMao(request);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ProductService.endpoint);

        toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: record.name }));
      },

      onError: (e: any) => {
        error(t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: record.name }), e);
      },
    },
  );

  return (
    <ButtonWithPopup
      button={{
        color: Color.PRIMARY,

        icon: 'check',
      }}
      submit={{
        label: t('PRODUCT.MAO_PAGE.FORM.CTA'),

        onClick: () => {
          return mutateUpdate.mutateAsync(record);
        },
      }}
      popup={{
        title: t('PRODUCT.MAO_PAGE.FORM.TITLE'),
      }}>
      <p>
        <Trans
          i18nKey="PRODUCT.MAO_PAGE.FORM.DESCRIPTION"
          values={{ productName: record.name }}
          components={{
            bold: <span className="font-weight-bold" />,
          }}
        />
      </p>
    </ButtonWithPopup>
  );
};
