import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from '@socialbrothers/components/Containers';
import { Icon, Spinner } from '@socialbrothers/components/UI';
import { Field, KeyValues, Operator } from '@socialbrothers/constants';
import { useTable, useToggle } from '@socialbrothers/hooks';
import { formatFilterToFormValues, getFilterableFields } from '@socialbrothers/utils';
import { Yup } from '@socialbrothers/utils';

import styles from './Filter.module.scss';
import { FilterFormEntry, FilterFormValues, FilterProps } from './Filter.props';

const Filter = forwardRef((props: FilterProps, ref: any) => {
  const { t } = useTranslation();
  const table = useTable();
  const { isToggle, toggle, setToggle } = useToggle(true);

  const validationSchema = Yup.object().shape({
    filter: Yup.array(
      Yup.object().shape({
        field: Yup.string().required(),
        value: Yup.string().required(),
      }),
    ),
  });

  const initialFilterValues: any = table.filter.filtersJson || {};

  const methods = useForm<FilterFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      filter: formatFilterToFormValues(getSnapshot(initialFilterValues)),
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields, prepend, remove } = useFieldArray({
    control: methods.control,

    name: 'filter',
  });

  const values: any = methods.watch('filter');

  const valuesJSON = JSON.stringify(values);

  useImperativeHandle(ref, () => ({
    append: (value?: FilterFormEntry) => {
      setToggle(true);
      prepend(
        value || {
          field: '',
          operator: '',
          value: '',
        },
      );
    },
  }));

  /**
   * Saves the filter(s) to the store and update the query with
   * the new data.
   *
   * @param data
   */
  const onSubmit = useCallback(
    (data: any) => {
      table.filter.setFilter(data.filter);
    },
    [table.filter],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFilters = useCallback(
    debounce(() => methods.handleSubmit(onSubmit)(), 500),
    [methods.handleSubmit],
  );

  useEffect(() => {
    debounceFilters();
  }, [debounceFilters, valuesJSON]);

  /**
   * Gets an key value object of operators that match the given field
   * type found by the source.
   *
   * @param source
   * @returns { key: value }
   */
  const getOperator = (source: string) => {
    const field = table.fields.find((foundField) => foundField.source === source);

    if (field) {
      switch (field.type) {
        case Field.BOOLEAN_FIELD:
          return [
            {
              key: Operator.IS,
              value: t('TABLE.HEADER.FILTER.OPERATORS.IS'),
            },
          ];
        case Field.DATE_FIELD:
          return [
            { key: Operator.IS, value: t('TABLE.HEADER.FILTER.OPERATORS.IS') },
            { key: Operator.LOWER_THAN, value: t('TABLE.HEADER.FILTER.OPERATORS.LOWER_THAN') },
            { key: Operator.GREATER_THAN, value: t('TABLE.HEADER.FILTER.OPERATORS.GREATER_THAN') },
            {
              key: Operator.LOWER_THAN_OR_EQUAL,
              value: t('TABLE.HEADER.FILTER.OPERATORS.LOWER_THAN_OR_EQUAL'),
            },
            {
              key: Operator.GREATER_THAN_OR_EQUAL,
              value: t('TABLE.HEADER.FILTER.OPERATORS.GREATER_THAN_OR_EQUAL'),
            },
          ];
        case Field.PRICE_FIELD:
        case Field.NUMBER_FIELD:
          return [
            { key: Operator.IS, value: t('TABLE.HEADER.FILTER.OPERATORS.IS') },
            { key: Operator.IS_NOT, value: t('TABLE.HEADER.FILTER.OPERATORS.IS_NOT') },
            { key: Operator.LOWER_THAN, value: t('TABLE.HEADER.FILTER.OPERATORS.LOWER_THAN') },
            { key: Operator.GREATER_THAN, value: t('TABLE.HEADER.FILTER.OPERATORS.GREATER_THAN') },
            {
              key: Operator.LOWER_THAN_OR_EQUAL,
              value: t('TABLE.HEADER.FILTER.OPERATORS.LOWER_THAN_OR_EQUAL'),
            },
            {
              key: Operator.GREATER_THAN_OR_EQUAL,
              value: t('TABLE.HEADER.FILTER.OPERATORS.GREATER_THAN_OR_EQUAL'),
            },
          ];
        default:
          return [
            { key: Operator.IS, value: t('TABLE.HEADER.FILTER.OPERATORS.IS') },
            { key: Operator.IS_NOT, value: t('TABLE.HEADER.FILTER.OPERATORS.IS_NOT') },
          ];
      }
    }

    return [];
  };

  /**
   * Gets an form input element that matches the given field found
   * by the source.
   *
   * @param source
   * @param index
   * @returns JSX.Element
   */
  const getField = (source: string, index: number, defaultValue?: string) => {
    const field = table.fields.find((foundField) => foundField.source === source);
    const name = `filter.${index}.value`;

    const booleanOptions = [
      { key: 'true', value: t('TABLE.HEADER.FILTER.TRUE') },
      { key: 'false', value: t('TABLE.HEADER.FILTER.FALSE') },
    ];

    if (field) {
      switch (field.type) {
        case Field.DATE_FIELD:
          return (
            <Form.Input.Date
              initialDate={defaultValue ? new Date(defaultValue) : undefined}
              name={name}
            />
          );
        case Field.BOOLEAN_FIELD:
          return (
            <Form.Input.Select defaultValue={defaultValue} name={name} options={booleanOptions} />
          );
        case Field.RELATION_FIELD:
          return field.options && field.options.length > 0 ? (
            <Form.Input.Select defaultValue={defaultValue} name={name} options={field.options} />
          ) : (
            <Spinner size={24} color="secondary" className={styles.Filter__Loader} />
          );
        case Field.ENUM_FIELD:
          const formattedValues: KeyValues = Object.values(field.enumeration).map((value: any) => {
            return {
              key: value,
              value: '' + t(`ENUM.${field.name}.${value}` as any),
            };
          });

          return (
            <Form.Input.Select defaultValue={defaultValue} name={name} options={formattedValues} />
          );
      }
    }

    return <Form.Input.Text defaultValue={defaultValue} name={name} />;
  };

  if (fields.length > 0) {
    return (
      <div className={styles.FilterWrapper} {...props}>
        <FormProvider {...methods}>
          <div className={styles.Toggle} onClick={toggle}>
            {isToggle
              ? t('TABLE.HEADER.FILTER.HIDE', { count: fields.length })
              : t('TABLE.HEADER.FILTER.SHOW', { count: fields.length })}
          </div>

          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className={cn(styles.Filter, {
              [styles['Filter--Hidden']]: !isToggle,
            })}>
            {fields.map((field: any, index) => {
              return (
                <Form.Layout.Row key={field.id} className={styles.Filter__Row}>
                  <Form.Layout.Field translationKey="TABLE.HEADER.FILTER.NAME">
                    <Form.Input.Select
                      defaultValue={field.field}
                      name={`filter.${index}.field`}
                      options={getFilterableFields(table.fields)}
                    />
                  </Form.Layout.Field>

                  <Form.Layout.Field translationKey="TABLE.HEADER.FILTER.OPERATOR">
                    <Form.Input.Select
                      defaultValue={field.operator}
                      name={`filter.${index}.operator`}
                      options={getOperator(values[index]?.field)}
                    />
                  </Form.Layout.Field>

                  <Form.Layout.Field translationKey="TABLE.HEADER.FILTER.VALUE">
                    {getField(values[index]?.field, index, field.value)}
                  </Form.Layout.Field>

                  <div className={styles.Filter__Delete} onClick={() => remove(index)}>
                    <Icon icon="trash-alt" />
                  </div>
                </Form.Layout.Row>
              );
            })}
          </form>
        </FormProvider>
      </div>
    );
  }

  return <div {...props}></div>;
});

export default observer(Filter);
