import { useTranslation } from 'react-i18next';

import { AuthForgotPasswordForm } from '@socialbrothers/components/Forms';
import { PageUnauthenticated } from '@socialbrothers/components/Layout';
import { useTheme } from '@socialbrothers/hooks';

export const AuthForgotPasswordPage = () => {
  const { forgotPasswordImage } = useTheme();
  const { t } = useTranslation();

  const initialValues = {
    email: '',
    password: '',
  };

  return (
    <PageUnauthenticated
      imageUri={forgotPasswordImage}
      title={t('AUTHENTICATION.FORGOT_PASSWORD.PAGE_TITLE')}
      description={t('AUTHENTICATION.FORGOT_PASSWORD.PAGE_DESCRIPTION')}>
      <AuthForgotPasswordForm initialValues={initialValues} />
    </PageUnauthenticated>
  );
};
