import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { PartnerForm } from '@Components/forms';

export const PartnerCreatePage = () => {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={false} title={t('PARTNER.CREATE_PAGE.TITLE')}>
      <Card title={t('PRODUCT.PLURAL')}>
        <PartnerForm />
      </Card>
    </Page>
  );
};
