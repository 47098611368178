import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { Operator } from '@socialbrothers/constants';

import { OrderOverviewApproveForm } from '@Components/forms';
import { OrderTable } from '@Components/tables';
import { IOrder, OrderStatus } from '@Services/Order';

export const OrderOverviewApprovePage = () => {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={false} title={t('ORDER.OVERVIEW_APPROVE_PAGE.TITLE')}>
      <Card title={t('ORDER.PLURAL')}>
        <OrderTable
          type="Open"
          status={[
            {
              operator: Operator.IS,
              value: OrderStatus.PROCESSING,
            },
          ]}>
          <Table.Field.ResourceAction
            editConfig={(record: IOrder) => ({
              icon: 'info-circle',
              link: `/orders/${record.id}`,
            })}>
            <OrderOverviewApproveForm />
          </Table.Field.ResourceAction>
        </OrderTable>
      </Card>
    </Page>
  );
};
