import cn from 'classnames';
import { observer, useLocalObservable } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Table } from '@socialbrothers/components/Containers';
import { Button, DebounceInput, Pagination } from '@socialbrothers/components/UI';
import { Storage } from '@socialbrothers/helpers';
import { TableProvider, useTable } from '@socialbrothers/hooks';
import TableStore, { SortDirection } from '@socialbrothers/stores/Table/TableStore';
import { getFilterableFields } from '@socialbrothers/utils';

import Filter from '../Filter/Filter';
import styles from './ResourceTable.module.scss';
import { ResourceTableProps } from './ResourceTable.props';

const TableHeader = observer(() => {
  const { t } = useTranslation();
  const table = useTable();
  const ref = useRef();

  const onChange = ({ value }: { value: string }) => {
    if (table.options.searchable) {
      table.filter.setSearch(value);
    }
  };

  return (
    <>
      <div className={styles.TableHeader}>
        {table.options.searchable && (
          <div className={styles.TableHeader__Search}>
            <DebounceInput placeholder={t('TABLE.HEADER.SEARCH')} onChange={onChange} />
          </div>
        )}

        {table.fields && Object.keys(getFilterableFields(table.fields)).length > 0 && (
          <Button
            className={styles.TableHeader__Add}
            icon="plus"
            type="button"
            link
            onClick={() => (ref as any).current.append()}>
            {t('TABLE.HEADER.FILTER.ADD')}
          </Button>
        )}
      </div>

      {table.fields && Object.keys(getFilterableFields(table.fields)).length > 0 && (
        <Filter ref={ref} />
      )}
    </>
  );
});

const TableFooter = observer(() => {
  const table = useTable();
  const { t } = useTranslation();

  const onChange = (page: number) => {
    table.filter.setPage(page);
  };

  return (
    <div className={styles.TableFooter}>
      <div className={styles.PerPage}>
        <div>{t('TABLE.FOOTER.SHOW')}</div>

        <select
          onChange={(event) => table.filter.setPerPage(parseInt(event.target.value))}
          value={table.filter.perPage}>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>

        <div>{t('TABLE.FOOTER.SHOW_ROWS')}</div>

        <div className={styles.Results}>
          {t('TABLE.FOOTER.RESULTS', {
            min: (table.filter.page - 1) * table.filter.perPage + 1,
            max:
              table.filter.page * table.filter.perPage > table.filter.totalResults
                ? table.filter.totalResults
                : table.filter.page * table.filter.perPage,
            total: table.filter.totalResults,
          })}
        </div>
      </div>

      <Pagination
        className={styles.Pagination}
        count={table.filter.totalResults}
        perPage={table.filter.perPage}
        onChange={(props) => onChange(props)}
      />
    </div>
  );
});

function ResourceTable<T>({
  children,
  service,
  filters,
  searchable,
  actions,
  className,
  persistFilterableFields,
}: ResourceTableProps<T>) {
  const [results, setResults] = useState([]);

  const fields = children.filter(Boolean).map((child: JSX.Element) => {
    return {
      ...{ type: child.type.displayName },
      ...child.props,
    };
  });

  const filter = useLocalObservable(() => {
    const savedFilters = persistFilterableFields
      ? Storage.getTableOptions(persistFilterableFields)
      : {};

    const defaultOptions = {
      perPage: 25,
      page: 1,
      totalResults: -1,
      filtersJson: savedFilters as any,
      defaultFilters: filters as any,
      sortBy: '',
      searchPhrase: '',
      sortDirection: SortDirection.DESCENDING,
    };

    return TableStore.create(defaultOptions);
  });

  const provider = {
    data: results,
    fields: fields,
    filter: filter,
    service: service,
    options: {
      searchable: searchable,
    },
  };

  const { data, isSuccess, isFetching } = useQuery(
    [service.endpoint, JSON.stringify({ ...filter.getFilter })],
    () => {
      return service.getList(filter.getFilter as any) as any;
    },
    { keepPreviousData: true },
  );

  useEffect(() => {
    if (isSuccess) {
      setResults(data.results);
      filter.setTotalResults(data.total);
      if (persistFilterableFields) {
        Storage.setTableOptions(persistFilterableFields, getSnapshot(filter.filtersJson));
      }
    }
  }, [isSuccess, data, filter, persistFilterableFields]);

  return (
    <TableProvider value={provider}>
      <div className={cn(styles.ResourceTable, className)}>
        {isFetching}

        <TableHeader />

        <Table.Base actions={actions} data={results} filter={filter}>
          {children}
        </Table.Base>

        <TableFooter />
      </div>
    </TableProvider>
  );
}

export default observer(ResourceTable);
