import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { ProductPalletTable, ProductTable } from '@Components/tables';

export const ProductOverviewPage = () => {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={false} title={t('PRODUCT.OVERVIEW_PAGE.TITLE')}>
      <Card title={t('PRODUCT.STOCK_AMOUNT')}>
        <ProductPalletTable />
      </Card>

      <Card title={t('PRODUCT.PLURAL')}>
        <ProductTable />
      </Card>
    </Page>
  );
};
