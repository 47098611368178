import { observer } from 'mobx-react';
import { cloneElement } from 'react';

import styles from './DetailTable.module.scss';
import { DetailTableBodyProps, DetailTableProps } from './DetailTable.props';

const TableBody = observer(({ fields, data }: DetailTableBodyProps) => {
  const getTableBody = () => (
    <tbody>
      {fields.map((item: JSX.Element, index: number) => {
        return (
          <tr key={index} className={styles.BaseTable__Row}>
            <th>{item.props.label}</th>

            <td title={item.props.tooltip} key={item.props.source || index}>
              {cloneElement(item, { sortable: null, filterable: null, record: data })}
            </td>
          </tr>
        );
      })}
    </tbody>
  );

  if (data) {
    return <table>{getTableBody()}</table>;
  }

  return <></>;
});

function DetailTable<T>({ data, children }: DetailTableProps<T>) {
  return (
    <div className={styles.Wrapper}>
      <TableBody data={data} fields={children} />
    </div>
  );
}

export default observer(DetailTable);
