import { format } from 'date-fns';

export const formatDate = (date: Date) => {
  return format(date, 'dd-MM-yyyy');
};

export const formatDateTime = (date: Date) => {
  return format(date, 'dd-MM-yyyy HH:mm');
};

export const getCurrentDateTime = () => {
  return format(new Date(), 'dd-MM-yyyy_HH:mm');
};
