import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Form } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { useTheme } from '@socialbrothers/hooks';
import { Yup } from '@socialbrothers/utils';

import Props, { AuthResetPasswordFormValues } from './AuthResetPasswordForm.props';

export const AuthResetPasswordForm = ({ initialValues, ...props }: Props) => {
  const { t } = useTranslation();
  const { authService } = useTheme();
  const location = useLocation();
  const [error, setError] = useState('');
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    password: Yup.string().password().required(),
    passwordConfirmation: Yup.string().password('password').required(),
  });

  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const email = params.get('email');

  useEffect(() => {
    if (!token || !email) {
      history.replace('/');
    }
  }, [token, email, history]);

  const handleSubmit = async ({ password, passwordConfirmation }: AuthResetPasswordFormValues) => {
    if (email && token) {
      try {
        await authService.resetPassword(email, token, password, passwordConfirmation);

        toast.success(t('AUTHENTICATION.RESET_PASSWORD.RESET_SUCCESSFUL'));
        history.push('/');
      } catch (e) {
        setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
      }
    }
  };

  return (
    <Form.Base onSubmit={handleSubmit} validationSchema={validationSchema} error={error} {...props}>
      <Form.Layout.Field translationKey="AUTHENTICATION.LABELS.NEW_PASSWORD">
        <Form.Input.Text type="password" name="password" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="AUTHENTICATION.LABELS.NEW_PASSWORD_VERIFY">
        <Form.Input.Text type="password" name="passwordConfirmation" />
      </Form.Layout.Field>

      <div className="d-flex align-items-center">
        <Button block type="submit">
          {t('AUTHENTICATION.RESET_PASSWORD.SUBMIT_LABEL')}
        </Button>

        <Link to="" className="link ml-auto">
          {t('AUTHENTICATION.RESET_PASSWORD.BACK')}
        </Link>
      </div>
    </Form.Base>
  );
};
