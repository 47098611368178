import { get } from 'lodash';

import { TextFieldProps } from './TextField.props';

const TextField = ({ source, record, className, ...props }: TextFieldProps) => {
  const value = get(record, source);

  return (
    <span className={className} {...props}>
      {typeof value !== 'string' ? JSON.stringify(value) : value}
    </span>
  );
};

TextField.displayName = 'TextField';

export default TextField;
