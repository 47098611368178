import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { IProduct, ProductStatus } from '@Services/Product';
import { getIcariusProductUrl } from '@Utils/StringUtils';

import { ProductDetailTableProps } from './ProductDetailTable.props';

export const ProductDetailTable = ({ data }: ProductDetailTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Detail<IProduct> data={data}>
      <Table.Field.Function
        source="name"
        label={t('PRODUCT.LABELS.NAME')}
        render={(item: IProduct) => (
          <a
            className="link color-primary"
            target="_blank"
            href={getIcariusProductUrl(item.slug)}
            rel="noreferrer">
            {item.name}
          </a>
        )}
      />

      <Table.Field.Text source="sku" label={t('PRODUCT.LABELS.SKU')} />

      <Table.Field.Price source="price" label={t('PRODUCT.LABELS.PRICE')} />

      <Table.Field.Number source="grossWeight" label={t('PRODUCT.LABELS.GROSS_WEIGHT')} />

      <Table.Field.Number source="netWeight" label={t('PRODUCT.LABELS.NET_WEIGHT')} />

      <Table.Field.Number source="stock" label={t('PRODUCT.LABELS.STOCK')} />

      <Table.Field.Enum
        source="status"
        enumeration={ProductStatus}
        name="PRODUCT.STATUS"
        label={t('PRODUCT.LABELS.STATUS')}
      />

      <Table.Field.Date
        showTime
        source="supplierRegisteredAt"
        label={t('PRODUCT.LABELS.SUPPLIER_REGISTERED_AT')}
      />
    </Table.Detail>
  );
};
