import cn from 'classnames';
import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './RadioInput.module.scss';
import { RadioInputProps } from './RadioInput.props';

const RadioInput = ({ options, inline = false, name, ...props }: RadioInputProps, ref: any) => {
  const { register } = useFormContext();

  return (
    <div ref={ref} className={cn(styles.RadioInput, { [styles.Inline]: inline })} {...props}>
      {options.map(({ key, value }) => {
        return (
          <div className={styles.Option} key={key}>
            <input type="radio" name={name} value={key} id={key} ref={register()} />
            <label htmlFor={key}>{value}</label>
          </div>
        );
      })}
    </div>
  );
};

export default forwardRef(RadioInput);
