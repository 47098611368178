import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Button } from '@socialbrothers/components/UI';

import { OrderDownloadRequest, OrderService } from '@Services/Order';

import { ResourceButtonProps } from './ResourceButton.props';

export const ResourceButton = ({ record, icon, documentType }: ResourceButtonProps) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const mutateUpdate = useMutation(
    (request: OrderDownloadRequest) => {
      return OrderService.getDownload(request);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(OrderService.endpoint);
      },

      onError: (e: any) => {
        captureException(e);

        toast.error(t('GLOBAL.UPDATED_UNSUCCESSFUL'));
      },
    },
  );

  if (!record) {
    return null;
  }

  const handleClick = () => {
    const request = {
      id: record.id,
      orderNr: record.orderNr,
      documentType,
    };

    mutateUpdate.mutateAsync(request);
  };

  return <Button onClick={handleClick} icon={icon} />;
};
