import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Form } from '@socialbrothers/components/Containers';
import { Button, Modal } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';
import { enumToOptions, Yup } from '@socialbrothers/utils';

import { useBoolean } from '@Hooks/common';
import {
  CombinedOrderDeliveryMethod,
  OrderDeliveryMethod,
  OrderSendRequest,
  OrderService,
} from '@Services/Order';

import { OrderOverviewSendFormProps } from './OrderOverviewSendForm.props';
export const OrderOverviewSendForm = ({
  onClose,
  isVisible,
  selectedOrderIds,
  deliveryMethod,
}: OrderOverviewSendFormProps) => {
  const { value: hasPalletSelect, setValue: setHasPalletSelect } = useBoolean(false);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    deliveryMethod: Yup.string().required(),
    orderIds: Yup.array().of(Yup.string().min(1)),
    deliveryDate: Yup.string().required(),
    taxed: Yup.string().required(),
    palletNumber: Yup.string(),
  });

  const queryClient = useQueryClient();

  const mutatePost = useMutation(
    (request: OrderSendRequest) => {
      return OrderService.sendDelivery(request);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(OrderService.endpoint);

        onClose();

        toast.success(
          t('GLOBAL.SENT_SUCCESSFUL', {
            name: `${t('ORDER.SINGLE')}`,
          }),
        );
      },
      onError: (e: any) => {
        captureException(e);

        toast.error(
          t('GLOBAL.SENT_UNSUCCESSFUL', {
            name: `${t('ORDER.SINGLE')}`,
          }),
        );
      },
    },
  );

  const handleSubmit = (values: OrderSendRequest) => {
    const params = {
      deliveryMethod: values.deliveryMethod,
      deliveryDate: values.deliveryDate,
      taxed: !!Number(values.taxed),
      orderIds: selectedOrderIds,
      ...(hasPalletSelect && { palletNumber: values.palletNumber }),
    };

    return mutatePost.mutateAsync(params);
  };

  const getAvailableDeliveryMethods = () => {
    return selectedOrderIds.length > 1
      ? enumToOptions(CombinedOrderDeliveryMethod, 'ORDER.DELIVERY_METHOD')
      : enumToOptions(OrderDeliveryMethod, 'ORDER.DELIVERY_METHOD');
  };

  const getInitialDeliveryMethod = () => {
    return deliveryMethod ? deliveryMethod : OrderDeliveryMethod.BOUW;
  };

  const handleSelectDeliveryMethod = (value: OrderDeliveryMethod) => {
    setHasPalletSelect(value === OrderDeliveryMethod.BOUW_PICKUP);
  };

  const initialValues = {
    deliveryMethod: getInitialDeliveryMethod(),
  };

  const palletSelectOptions = new Array(15).fill(null).map((_, idx) => ({
    key: idx + 1,
    value: idx + 1,
  }));

  return (
    <Modal title={t('ORDER.OVERVIEW_SEND_PAGE.FORM.TITLE')} onClose={onClose} isVisible={isVisible}>
      <Form.Base
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        <Form.Layout.Field
          label={t('ORDER.LABELS.DELIVERY_METHOD')}
          onChange={handleSelectDeliveryMethod}>
          <Form.Input.Radio name="deliveryMethod" options={getAvailableDeliveryMethods()} />
        </Form.Layout.Field>

        <Form.Layout.Field label={t('ORDER.LABELS.DELIVERY_DATE')}>
          <Form.Input.Date name="deliveryDate" initialDate={new Date()} />
        </Form.Layout.Field>

        <Form.Layout.Field label={t('ORDER.LABELS.TAX')}>
          <Form.Input.Select
            name="taxed"
            defaultValue={1}
            options={[
              {
                key: 0,
                value: t('ORDER.OVERVIEW_SEND_PAGE.FORM.INPUT.UNTAXED').toString(),
              },
              {
                key: 1,
                value: t('ORDER.OVERVIEW_SEND_PAGE.FORM.INPUT.TAXED').toString(),
              },
            ]}
          />
        </Form.Layout.Field>

        {hasPalletSelect && (
          <Form.Layout.Field label={t('ORDER.LABELS.PALLET')}>
            <Form.Input.Select name="palletNumber" defaultValue="1" options={palletSelectOptions} />
          </Form.Layout.Field>
        )}

        <hr />

        <div className="d-flex justify-content-end">
          <Button type="button" onClick={onClose} color={Color.GRAY}>
            {t('RESOURCE.DELETE.MODAL.CANCEL')}
          </Button>

          <Button label={t('ORDER.OVERVIEW_SEND_PAGE.FORM.CTA')} type="submit" />
        </div>
      </Form.Base>
    </Modal>
  );
};
