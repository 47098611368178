import { BaseModel } from '@socialbrothers/constants';

import { ILog } from '@Services/Log';

export enum ProductStatus {
  CREATED = 'Created',
  MAO_REQUESTED = 'MaoRequested',
  MAO_APPROVED = 'MaoApproved',
}

export interface IProduct extends BaseModel {
  wooCommerceProductId: string;
  name: string;
  slug: string;
  sku: string;
  price: number;
  grossWeight: number;
  netWeight: number;
  supplierRegisteredAt: string;
  status: ProductStatus;
  stock: number;
  logs: ILog[];
}

export interface ProductMaoRequest {
  id: string;
}

export interface ProductVioRequest {
  deliveryDate: string;
  taxed: boolean;
  isReturn: boolean;
  productStockLines: {
    productId: string;
    quantity: number;
  }[];
  logisticsServiceProvider: {
    name: string;
    address: string;
    city: string;
    postcode: string;
  };
}

export interface IPallet extends BaseModel {
  palletOccupation: number;
  stock: number;
}
