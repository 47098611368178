import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { TokenResponse } from '@socialbrothers/constants';

export class BaseService {
  protected api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL,
    });
  }

  setAccessToken = (token?: TokenResponse) => {
    if (token) {
      this.api.defaults.headers.Authorization = `Bearer ${token.accessToken}`;
    } else {
      this.api.defaults.headers.Authorization = null;
    }
  };

  removeAccessToken = () => {
    delete this.api.defaults.headers.Authorization;
  };

  get = <T>(url: string, args?: Object) => {
    return this.api.get<T>(url, {
      params: args,
    });
  };

  post = <T>(url: string, args?: Object, options?: AxiosRequestConfig) => {
    return this.api.post<T>(url, this.format(args), {
      ...options,
      headers: { 'Content-Type': 'application/json' },
    });
  };

  put = <T>(url: string, args?: Object, options?: AxiosRequestConfig) => {
    return this.api.put<T>(url, this.format(args), {
      ...options,
      headers: { 'Content-Type': 'application/json' },
    });
  };

  delete = <T>(url: string, args?: Object) => {
    return this.api.delete<T>(url, {
      data: args,
    });
  };

  private format = (args: any) => {
    return args;
  };
}
