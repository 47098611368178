import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { PartnerForm } from '@Components/forms';
import { IPartner, PartnerService } from '@Services/Partner';

export const PartnerUpdatePage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useResource<IPartner>(PartnerService, id);

  if (!data) {
    return null;
  }

  return (
    <Page breadcrumbs={false} title={`${t('PARTNER.SINGLE')}: ${data.name}`}>
      <Card title={t('PARTNER.UPDATE_PAGE.TITLE')}>
        <PartnerForm />
      </Card>
    </Page>
  );
};
